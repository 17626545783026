// List of music genres
const musicGenres = [
    "Pop Rock",
    "House",
    "Vocal",
    "Experimental",
    "Punk",
    "Alternative Rock",
    "Synth-Pop",
    "Techno",
    "Indie Rock",
    "Soul",
    "Ambient",
    "Disco",
    "Hardcore",
    "Folk",
    "Country",
    "Ballad",
    "Hard Rock",
    "Electro",
    "Rock & Roll",
    "Chanson",
    "Trance",
    "Heavy Metal",
    "Romantic",
    "Psychedelic Rock",
    "Folk Rock",
    "Soundtrack",
    "Downtempo",
    "Noise",
    "Schlager",
    "Prog Rock",
    "Classic Rock",
    "Funk",
    "Easy Listening",
    "Black Metal",
    "Tech House",
    "Blues Rock",
    "Rhythm & Blues",
    "New Wave",
    "Industrial",
    "Deep House",
    "Classical",
    "Death Metal",
    "Drum N Bass",
    "Progressive House",
    "Euro House",
    "Soft Rock",
    "Garage Rock",
    "Abstract",
    "Gospel",
    "Europop",
    "Minimal",
    "Acoustic",
    "Thrash",
    "Baroque",
    "Modern",
    "Swing",
    "Big Band",
    "Indie Pop",
    "Dub",
    "Country Rock",
    "Drone",
    "Contemporary Jazz",
    "Breakbeat",
    "Progressive Trance",
    "Opera",
    "Holiday",
    "Contemporary",
    "IDM",
    "Dancehall",
    "Breaks",
    "African",
    "Reggae",
    "Dark Ambient",
    "Art Rock",
    "Fusion",
    "Pop Rap",
    "Avantgarde",
    "Beat",
    "Hard Trance",
    "Electro House",
    "Acid",
    "Instrumental",
    "Rockabilly",
    "Jazz-Rock",
    "Hard House",
    "Cumbia",
    "Free Improvisation",
    "Emo",
    "Musical",
    "Trip Hop",
    "J-Pop",
    "Hardcore Hip-Hop",
    "Progressive Metal",
    "Italodance",
    "Dixieland",
    "AOR",
    "Arena Rock",
    "Southern Rock",
    "Symphonic Rock"
];

// List of all countries
const countries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia", "Austria",
    "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan",
    "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia",
    "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo (Congo-Brazzaville)", "Costa Rica",
    "Croatia", "Cuba", "Cyprus", "Czechia (Czech Republic)", "Democratic Republic of the Congo", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador",
    "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini (fmr. Swaziland)", "Ethiopia", "Fiji", "Finland", "France",
    "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau",
    "Guyana", "Haiti", "Holy See", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq",
    "Ireland", "Israel", "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kuwait",
    "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg",
    "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico",
    "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar (formerly Burma)", "Namibia", "Nauru",
    "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Korea", "North Macedonia (formerly Macedonia)", "Norway", "Oman",
    "Pakistan", "Palau", "Palestine State", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal",
    "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe",
    "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia",
    "South Africa", "South Korea", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria",
    "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan",
    "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States of America", "Uruguay", "Uzbekistan", "Vanuatu", "Venezuela",
    "Vietnam", "Yemen", "Zambia", "Zimbabwe"
];

// List of decades from 1900 to present, including abbreviated formats
const decades = [
    "1900s", "1910s", "1920s", "1930s", "1940s", "1950s", "1960s", "1970s", "1980s", "1990s",
    "2000s", "2010s", "2020s", "00s", "10s", "20s", "30s", "40s", "50s", "60s", "70s", "80s", "90s"
];

// Merged list of music genres, countries, and decades
export const tags = [...musicGenres, ...countries, ...decades];