import React from "react";
import { WithContext as ReactTags, SEPARATORS } from 'react-tag-input';
import { tags as suggestions } from '../assets/tags';
import "./Modal.css";

const Modal = ({ isOpen, onClose, onSave, quizzlist }) => {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [tags, setTags] = React.useState([]);
  const [showTracks, setShowTracks] = React.useState(false);

  if (!isOpen) return null;

  const handleDelete = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const handleAddition = (tag) => {
    setTags((prevTags) => [...prevTags, tag]);
  };

  const handleTagClick = (index) => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  const toggleShowTracks = () => {
    setShowTracks((prevShowTracks) => !prevShowTracks);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Enter Quizzlist Details</h2>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter name"
        />
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter description"
          rows="3"
          className="description-input"
        />
        <ReactTags
          tags={tags}
          suggestions={suggestions.map((tag) => ({ id: tag, text: tag }))}
          separators={[SEPARATORS.ENTER, SEPARATORS.COMMA]}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleTagClick={handleTagClick}
          inputFieldPosition="top"
          editable
          maxTags={7}
        />
        <button onClick={toggleShowTracks}>
          {showTracks ? "Hide Tracks" : "Show Tracks"}
        </button>
        {showTracks && (
          <ul className="quizzlist-display">
            {quizzlist.map((track, index) => (
              <li key={index} className="quizzlist-item">
                {track.name} by {track.artists[0].name}
              </li>
            ))}
          </ul>
        )}
        <div className="modal-actions">
          <button onClick={onClose}>Cancel</button>
          <button
            onClick={() => onSave(name, description, quizzlist)}
            disabled={name.length < 3}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
